export class Customer {
  public customerId: number;
  public customerName: string;
  public isActiveInSafestock: boolean;
  public customerProductXrefs: any;
  public mainLogoURI: string;
  public defaultShipToId: string;
  public activateInforCustomerProductItemFetch: boolean | null = null;

  constructor(
    customerId: number = 0,
    customerName: string = '',
    isActiveInSafestock: boolean = false,
    customerProductXrefs: any = [],
    mainLogoURI: string = '',
    defaultShipToId: string = ''
  ) {
    this.customerId = customerId;
    this.customerName = customerName;
    this.isActiveInSafestock = isActiveInSafestock;
    this.customerProductXrefs = customerProductXrefs;
    this.mainLogoURI = mainLogoURI;
    this.defaultShipToId = defaultShipToId; 
  }
}
