<div class="ss-header-container">
  <!-- LEFT CONTAINER -->

  <div class="ss-header-left">
    <!-- MENU BUTTON -->

    <button
      *ngIf="this.authService.isLogged"
      kendoButton
      icon="menu"
      fillMode="flat"
      themeColor="secondary"
      (click)="toggleSidenav.emit()"
      class="ss-header-hover-pointer"
    ></button>

    <!-- LOGO -->

    <img src="assets/img/logo-safestock.png" />
    <p
      *ngIf="this.authService.isLogged && canSeeRemainingBudget"
      class="ss-header-small-blue-text"
    >
      {{ "layout.header.remainingBudget" | translate }} :
      <span class="ss-header-bold-red-text">{{ getDollorSignByLocale(remainingBudget) }}</span>
    </p>
  </div>

  <!-- RIGHT CONTAINER -->

  <div class="ss-header-right">


    <a
      class="k-link k-icon k-i-help"
      href="mailto:vmi@spi.com"
    ></a>

    <!-- MENU LANGUE-->

    <kendo-menu [openOnClick]="{ toggle: 'click' }">
      <kendo-menu-item>
        <ng-template kendoMenuItemTemplate
          ><span class="k-icon k-i-globe-outline"></span>
          {{ langue | uppercase }}</ng-template
        >
        <ng-template kendoMenuItemContentTemplate let-index="index">
          <div class="ss-header-menu-container ss-header-hover-pointer">
            <button mat-button (click)="selectLangue('fr')">Français</button>
            <button mat-button (click)="selectLangue('en')">English</button>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu>

    <!-- CART BUTTON -->

    <button
      class="ss-header-hover-pointer"
      *ngIf="this.authService.isLogged && showCartButton && numberOfItemsInCart > 0"
      kendoButton
      icon="cart"
      fillMode="flat"
      themeColor="secondary"
      (click)="goToCart()"
      [matBadge]="numberOfItemsInCart"
    ></button>
    <!--Version sans Badge, parce qu'il n'y pas d'item dans le cart-->
    <button
      class="ss-header-hover-pointer"
      *ngIf="this.authService.isLogged && showCartButton && numberOfItemsInCart === 0"
      kendoButton
      icon="cart"
      fillMode="flat"
      themeColor="secondary"
      (click)="goToCart()"
    ></button>

    <!-- MENU USAGER (PROFIL / LOGOUT )-->

    <kendo-menu
      [openOnClick]="{ toggle: 'click' }"
      *ngIf="this.authService.isLogged"
    >
      <kendo-menu-item>
        <ng-template kendoMenuItemTemplate
          ><span class="k-icon k-i-user"></span>
          {{ authService.userName }}</ng-template
        >
        <ng-template kendoMenuItemContentTemplate let-index="index">
          <div class="ss-header-menu-container">
            <!-- PROFILE -->

            <button mat-button routerLink="/auth/profile">
              <mat-icon>person</mat-icon>
              {{ "layout.header.button_profile" | translate }}
            </button>

            <!-- LOGOUT -->

            <button mat-button (click)="logout()">
              <mat-icon>logout</mat-icon>
              {{ "layout.header.button_logout" | translate }}
            </button>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu>
  </div>
</div>
