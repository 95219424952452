export class UserOrderOverview {
    
    public maxAvailableAmount: number;
    public amountRemaining: number;
    public actualPurchasedAmount: number;
    public minDateToCheck: Date;
    public nbOrderTotal: number;
    public nbOrderNotApproved: number;
    public nbOrderCompleted: number;
    public nbOrderInCartMode: number;
    public qtyConsumedByProductId: object;
 
    constructor( 
        maxAvailableAmount: number | null,
        amountRemaining: number | null,
        actualPurchasedAmount: number,
        minDateToCheck: Date,
        nbOrderTotal: number,
        nbOrderNotApproved: number,
        nbOrderCompleted: number,
        nbOrderInCartMode: number,
        qtyConsumedByProductId: object
    )   
    {
        this.maxAvailableAmount = maxAvailableAmount ? maxAvailableAmount : Infinity;
        this.amountRemaining = amountRemaining ? amountRemaining : Infinity;
        this.actualPurchasedAmount = actualPurchasedAmount;
        this.minDateToCheck = minDateToCheck;
        this.nbOrderTotal = nbOrderTotal;
        this.nbOrderNotApproved = nbOrderNotApproved;
        this.nbOrderCompleted = nbOrderCompleted;
        this.nbOrderInCartMode = nbOrderInCartMode;
        this.qtyConsumedByProductId = qtyConsumedByProductId;
    }
 
}