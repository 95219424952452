import { Directive, Input, HostListener, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[afterValueChanged]'
})
export class AfterValueChangedDirective implements OnDestroy {
  @Output()
  public afterValueChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public valueChangeDelay = 300;

  private stream: Subject<any> = new Subject<any>();
  private subscription: Subscription;

  //Pris de Kendo UI:https://www.telerik.com/kendo-angular-ui/components/inputs/debounce-valuechange/
  //Rajout de distinctUntilChanged pour s'assurer que le user a finis de d'écrire ce qu'il voulait avant de lancer les potentiels call http       

  constructor() {
    this.subscription = this.stream
      .pipe(debounceTime(this.valueChangeDelay))
      .pipe(distinctUntilChanged())
      .subscribe((value: any) => {
        this.afterValueChanged.next(value);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //Les deux events listener permet d'intercepté des composant kendo et des input html standard
  @HostListener('change', [ '$event' ])
  @HostListener('valueChange', [ '$event' ])
  public onValueChange(value: any): void {
    this.stream.next(value);
  }
  
}
