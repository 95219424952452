import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { FrameModule } from './layout/frame.module';

import { ErrorInterceptor } from './core/error/error.interceptor';
import { ErrorHandlerService } from './core/error/error-handler.service';

import { AppComponent } from './app.component';

import { LocalStorage } from './core/local-storage.enum';
import { IconsModule } from '@progress/kendo-angular-icons';

import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslationManagementService } from './core/translation/translate.service';

// translation loader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    FrameModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem(LocalStorage.JWT);
        },
        allowedDomains: [
          'safestock-api-dev.azurewebsites.net', 
          'safestock-api-staging.azurewebsites.net',
          'safestock-api-prod.azurewebsites.net',
          'localhost:7267',
          'localhost:4200',
        ],
      },
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IconsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    { 
      provide: MessageService, 
      useClass: TranslationManagementService 
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
