import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import {
  DialogService,
  DialogCloseResult,
  DialogRef,
  DialogContentBase,
} from '@progress/kendo-angular-dialog';
import {
  PageChangeEvent,
  GridDataResult,
  PagerPosition,
} from '@progress/kendo-angular-grid';

import { Subscription } from 'rxjs';

import { GridService } from 'src/app/shared/grid.service';
import { UserGroupService } from 'src/app/module/user-group/user-group.service';

@Component({
  selector: 'app-user-import',
  templateUrl: './user-import.component.html',
  styleUrls: ['./user-import.component.scss'],
})
export class UserImportComponent implements OnInit {
  dialogWidth = 1000;
  dialogHeight = 800;
  step = 0; // 1: usergroup, 2: file select, 3: confirm & submit
  isLoading = false;
  subscription!: Subscription;
  userGroupHeaderId!: number;
  userGroupDescription!: number;
  file!: any;
  gridView!: GridDataResult;
  pageable = {
    pageSizes: this.gridService.pageSizes,
  };
  public state: State = {
    take: 1000,
    skip: 0,
  };

  constructor(
    public dialog: DialogRef,
    private gridService: GridService,
    private userGroupService: UserGroupService
  ) {
    // fetch user group list
    this.refresh();
    // subscribe to data
    this.subscription = this.userGroupService.userGroupsOdata.subscribe(
      (response) => {
        this.gridView = {
          data: response.value,
          total: response['@odata.count'],
        };
      }
    );
  }

  ngOnInit(): void {
    this.goToStep(1);
  }

  onAction(e: any) {}

  close() {
    this.dialog.close();
  }

  dataStateChange(state: State) {
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.refresh();
  }

  goToStep(step: number) {
    if (step == 1) {
      this.dialogWidth = 1000;
      this.dialogHeight = 800;
    } else if (step == 2) {
      this.dialogWidth = 800;
      this.dialogHeight = 600;
    } else if (step == 3) {
      this.dialogWidth = 800;
      this.dialogHeight = 400;
    }
    this.step = step;
  }

  refresh() {
    this.isLoading = true;
    this.userGroupService
      .odataStr(
        this.state,
        '$expand=Customer(select=CustomerName)&$filter=isActiveInSafestock eq true'
      )
      .subscribe((response) => {
        this.isLoading = false;
      });
  }

  selectionner(e: any) {
    this.userGroupHeaderId = e.dataItem.userGroupHeaderId;
    this.userGroupDescription = e.dataItem.userGroupDescription;
    this.goToStep(2);
  }

  onFileChage(e: any) {
    this.file = e[0];
    this.goToStep(3);
  }

  confirm() {
    this.dialog.close({
      userGroupHeaderId: this.userGroupHeaderId,
      file: this.file,
    });
  }
}
