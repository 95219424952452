<kendo-dialog-titlebar (close)="close()">
  <div style="font-size: 18px; line-height: 1.3em">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->
<div class="ss-dialog-content">{{ message | translate }}</div>
<!-- BUTTONS -->
<kendo-dialog-actions>
  <button
    kendoButton
    themeColor="base"
    (click)="ok()"
    class="ss-pop-alert-button"
  >
    {{ "shared.button.ok" | translate }}
  </button>
</kendo-dialog-actions>
