import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout/layout.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/home', pathMatch: 'full' },
  {
    path: 'auth',
    component: LayoutComponent,
    loadChildren: () =>
      import('./module/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'customer',
    component: LayoutComponent,
    loadChildren: () =>
      import('./module/customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'order',
    component: LayoutComponent,
    loadChildren: () =>
      import('./module/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'product',
    component: LayoutComponent,
    loadChildren: () =>
      import('./module/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'user',
    component: LayoutComponent,
    loadChildren: () =>
      import('./module/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'user-group',
    component: LayoutComponent,
    loadChildren: () =>
      import('./module/user-group/user-group.module').then(
        (m) => m.UserGroupModule
      ),
    },
    
    {
      path: 'catalog',
      component: LayoutComponent,
      loadChildren: () =>
      import('./module/catalog/catalog.module').then((m) => m.CatalogModule),
    },
    {
      path: 'cart',
      component: LayoutComponent,
      loadChildren: () =>
      import('./module/cart/cart.module').then((m) => m.CartModule),
    },
    {
      path: 'checkout',
      component: LayoutComponent,
      loadChildren: () =>
      import('./module/checkout/checkout.module').then((m) => m.CheckoutModule),
    },
    {
      path: 'report',
      component: LayoutComponent,
      loadChildren: () =>
      import('./module/report/report.module').then((m) => m.ReportModule),
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
