export class UserOverride {
    
    public overrideEmployeMinimumAmountToSkipTransportFee: number | null;
    public overrideEmployeTransportFeeAmount: number | null;
    public overrideEmployeMaximumAllowedAmount: number | null;
 
    constructor( 
        overrideEmployeMinimumAmountToSkipTransportFee: number | null,
        overrideEmployeTransportFeeAmount: number | null,
        overrideEmployeMaximumAllowedAmount: number | null
    )   
    {
        this.overrideEmployeMinimumAmountToSkipTransportFee = overrideEmployeMinimumAmountToSkipTransportFee;
        this.overrideEmployeTransportFeeAmount = overrideEmployeTransportFeeAmount;
        this.overrideEmployeMaximumAllowedAmount = overrideEmployeMaximumAllowedAmount;
    }
 
}