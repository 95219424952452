import { Component } from '@angular/core';
import { DialogRef, DialogContentBase, } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-pop-confirm',
  templateUrl: './pop-confirm.component.html',
  styleUrls: ['./pop-confirm.component.scss'],
})
export class PopConfirmComponent extends DialogContentBase {
  title = '';
  message = '';
  button_yes = '';
  button_no = '';

  constructor(public override dialog: DialogRef) {
    super(dialog)
  }

  cancel() {
    this.dialog.close(false);
  }

  confirm() {
    this.dialog.close(true);
  }
}
