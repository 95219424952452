import { ProductProduct } from "./product-product.model";

export class Product {
  public productId : number;
  public productGroupingName: string;
  public maximumQty: number;
  public product : ProductProduct;
  public fullTextSearchField : string = '';

  public productCustomField01Name?:  string | null = null;
  public productCustomField02Name?:  string | null = null;
  public productCustomField03Name?:  string | null = null;
  public productCustomField01Value?: string | null = null;
  public productCustomField02Value?: string | null = null;
  public productCustomField03Value?: string | null = null;

  constructor(
    productId : number = 0,
    productGroupingName: string = '',
    maximumQty: number = 0,
    product : ProductProduct
  ) {
    this.productId = productId;
    this.productGroupingName = productGroupingName;
    this.maximumQty = maximumQty;
    this.product = product;
  }
}
