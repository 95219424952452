<kendo-dialog-titlebar (close)="cancel()">
  <div style="font-size: 18px; line-height: 1.3em">
    <span class="k-icon k-i-lock"></span>
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->

<div class="ss-popup-container">
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    [filterable]="true"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    [loading]="isLoading"
    class="ss-list-grid"
    (cellClick)="select($event)"
    (dataStateChange)="dataStateChange($event)"
  >
    <!-- COLUMNS -->

    <!-- ID -->

    <kendo-grid-column
      field="userGroupHeaderId"
      title="{{ 'module.user-group.model.userGroupHeaderId' | translate }}"
      [width]="80"
      [minResizableWidth]="80"
      filter="numeric"
    ></kendo-grid-column>

    <!-- DESCRIPTION -->

    <kendo-grid-column
      field="userGroupDescription"
      title="{{ 'module.user-group.model.userGroupDescription' | translate }}"
      [width]="200"
      [minResizableWidth]="200"
    ></kendo-grid-column>

    <!-- CUSTOMER ID -->

    <kendo-grid-column
      field="customer.customerId"
      title="{{ 'module.user-group.model.customerId' | translate }}"
      [width]="200"
      [minResizableWidth]="200"
      filter="numeric"
    ></kendo-grid-column>

    <!-- CUSTOMER -->

    <kendo-grid-column
      field="customer.customerName"
      title="{{ 'module.user-group.model.customerName' | translate }}"
      [width]="140"
      [minResizableWidth]="140"
    ></kendo-grid-column>

    <!-- MAX ALLOWED AMOUNT -->

    <kendo-grid-column
      field="maximumAllowedAmount"
      title="{{ 'module.user-group.model.maximumAllowedAmount' | translate }}"
      [width]="120"
      [minResizableWidth]="120"
      filter="numeric"
    ></kendo-grid-column>

    <!-- SUPERVISOR FIRSTNAME -->

    <kendo-grid-column
      field="supervisorUser.userFirstName"
      title="{{ 'module.user-group.list.supervisorFirstName' | translate }}"
      [width]="120"
      [minResizableWidth]="120"
    ></kendo-grid-column>

    <!-- SUPERVISOR LASTNAME -->

    <kendo-grid-column
      field="supervisorUser.userLastName"
      title="{{ 'module.user-group.list.supervisorLastName' | translate }}"
      [width]="120"
      [minResizableWidth]="120"
    >
    </kendo-grid-column>
  </kendo-grid>
</div>
