import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'src/app/core/local-storage.enum';

import { AuthService } from 'src/app/core/auth/auth.service';
import { GlobalDataService } from './core/global-data/global-data.service';
import { ProductInformationManagementService } from './core/product-information-management/product-information-management.service';

import { environment } from 'src/environments/environment';
import { TranslationManagementService } from './core/translation/translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private globalDataService: GlobalDataService,
    private pimService: ProductInformationManagementService,
    private translate: TranslationManagementService
  ) {
    // translation default & init
    var lang = localStorage.getItem(LocalStorage.LANG);
    if (!lang) {
      lang = 'fr';
      localStorage.setItem(LocalStorage.LANG, lang);
    }
    this.translate.changeCurrentLang(lang)
    // set localstorage defaults
    if (!localStorage.getItem(LocalStorage.GRID_ITEM_PER_PAGE))
      localStorage.setItem(LocalStorage.GRID_ITEM_PER_PAGE, '50');
  }

  public appVersion = environment.appVersion;
  ngOnInit() {
    this.authService.hydrate();
    const isLoginExpired = this.authService.checkIfLoginExpired();
    const isSameEnvironment = this.authService.checkIfSameEnvironment();
    const url = window.location + '';

    if(this.authService.isLogged) {
      this.globalDataService.loadGlobalData();
      this.pimService.initLoadCvlFields();
      this.pimService.initLoadCvlData();
    }

    if (url.indexOf('reset-password') != -1) {
      this.authService.logout(false).subscribe();
    } else if (isLoginExpired || !isSameEnvironment) {
      this.authService.logout(true).subscribe();
    }
  }
}
