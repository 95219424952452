export class ProductRestrictions {
    productId:number;
    productGroupingName:string | null;
    maxQuantity: number | null;

    constructor(productId:number,productGroupingName:string | null, maxQuantity: number | null) {
        this.productId = productId;

        this.productGroupingName = productGroupingName;
        if (productGroupingName === '')
            this.productGroupingName = null;
            

        this.maxQuantity = maxQuantity;
    }
}