<kendo-dialog-titlebar (close)="cancel()">
  <div style="font-size: 18px; line-height: 1.3em">
    <span class="k-icon k-i-lock"></span>
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->

<div class="ss-popup-container">
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    [filterable]="true"
    [resizable]="true"
    [selectable]="true"
    scrollable="scrollable"
    [loading]="isLoading"
    class="ss-list-grid"
    (cellClick)="select($event)"
    (dataStateChange)="dataStateChange($event)"
  >
    <!-- COLUMNS -->

    <!-- userName -->

    <kendo-grid-column
      field="userName"
      title="{{ 'module.user.model.userName' | translate }}"
      [width]="40"
      [minResizableWidth]="100"
    ></kendo-grid-column>

    <!-- FIRST NAME -->

    <kendo-grid-column
      field="userFirstName"
      title="{{ 'module.user.model.userFirstName' | translate }}"
      [width]="120"
      [minResizableWidth]="160"
    ></kendo-grid-column>

    <!-- LAST NAME -->

    <kendo-grid-column
      field="userLastName"
      title="{{ 'module.user.model.userLastName' | translate }}"
      [width]="120"
      [minResizableWidth]="160"
    ></kendo-grid-column>
  </kendo-grid>
</div>
