import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthToken } from './auth-token.model';
import { LocalStorage } from 'src/app/core/local-storage.enum';

const apiUrl = environment.apiUrl;

export interface AuthResponseData {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogged = false;
  userId = '';
  currentUserGroupHeaderId = 0;
  userName = '';
  userRole = '';
  currentCustomerId: number = 0;
  customerIds: number[] = []; 
  userGroupHeaderIds: number[] = []

  constructor(private http: HttpClient, private router: Router) {}

  login(username: string, password: string) {
    return this.http
      .post<AuthResponseData>(apiUrl + 'security/Auth/Login', {
        username: username,
        password: password,
      })
      .pipe(
        tap((resData) => {
          // decode
          const helper = new JwtHelperService(resData.accessToken);
          // set localstorage keys
          localStorage.setItem(LocalStorage.JWT, resData.accessToken); // on stocke le JWT dans la mémoire du navigateur
          localStorage.setItem(
            LocalStorage.JWT_LAST_STANDARD_SUCCESSFUL_LOGIN,
            resData.accessToken
          );
          localStorage.setItem(
            LocalStorage.JWT_SOURCE_SERVER,
            environment.apiUrl
          );
          
          // Demande de Félix, on vide le panier au Login
          localStorage.removeItem(LocalStorage.CART)

          // hydrate & redirect
          this.hydrate();
          this.router.navigate(['/auth/home']);
        })
      );
  }

  logout(redirect: boolean) {
    // remove localstorage keys
    localStorage.removeItem(LocalStorage.JWT);
    localStorage.removeItem(LocalStorage.JWT_LAST_STANDARD_SUCCESSFUL_LOGIN);
    localStorage.removeItem(LocalStorage.JWT_SOURCE_SERVER);
    // reset
    this.reset();
    // redirect
    if (redirect) this.router.navigate(['/auth']);
    // call api logout
    return this.http.post(apiUrl + 'security/Auth/logout', {}).pipe(
      tap((resData) => {})
    );
  }

  hydrate(): void {
    // decode token
    const helper = new JwtHelperService();
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    const decodedToken: AuthToken = helper.decodeToken(jwt) as any;
    if (decodedToken) {
      // assign
      this.isLogged = true;
      this.userId = decodedToken['UserID'];
      this.currentUserGroupHeaderId = decodedToken['UGID'] ? +JSON.parse(decodedToken['UGID'])[0] : -1;
      this.userGroupHeaderIds = decodedToken['UGID'] ? JSON.parse(decodedToken['UGID']) : []
      this.currentCustomerId = decodedToken['UGCID'] ? +JSON.parse(decodedToken['UGCID'])[0] : -1;
      this.customerIds = decodedToken['UGCID'] ? JSON.parse(decodedToken['UGCID']) : [-1];
      this.userName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
      this.userRole = decodedToken['MUT'];
    }
  }

  reset() {
    this.isLogged = false;
    this.userId = '';
    this.currentUserGroupHeaderId = 0;
    this.userName = '';
    this.userRole = '';
  }

  checkIfLoginExpired(): boolean {
    const helper = new JwtHelperService();
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    if (helper.isTokenExpired(jwt)) {
      return true;
    }
    return false;
  }

  checkIfSameEnvironment(): boolean {
    return (
      localStorage.getItem(LocalStorage.JWT_SOURCE_SERVER) ===
      environment.apiUrl
    );
  }

  public getUserId():string {
    return this.userId;
  }

  public getUserGroupId():number {
    return this.currentUserGroupHeaderId
  }

  public getCurrentCustomerId():number {
    return this.currentCustomerId
  }

  public getAllCustomerIds(): number[] {
    return this.customerIds
  }

  public setCurrentCustomerId(customerId:number):void {
    if(this.customerIds.indexOf(customerId) > 1) {
      this.currentCustomerId = customerId
    }
  }
}
