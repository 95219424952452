import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import {
  DialogService,
  DialogCloseResult,
  DialogRef,
  DialogContentBase,
} from '@progress/kendo-angular-dialog';
import {
  PageChangeEvent,
  GridDataResult,
  PagerPosition,
} from '@progress/kendo-angular-grid';

import { UserService } from '../user.service';
import { GridService } from 'src/app/shared/grid.service';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss'],
})
export class UserPopupComponent implements OnInit {
  oDataStr = '';
  title = '';
  button_cancel = '';

  isLoading = false;
  gridView!: GridDataResult;
  id!: number;

  pageable = {
    pageSizes: this.gridService.pageSizes,
  };

  public state: State = {
    take: this.gridService.take,
    skip: 0,
  };

  constructor(
    public dialog: DialogRef,
    private gridService: GridService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // api call odata
    this.refreshList();
  }

  refreshList() {
    this.isLoading = true;
    this.userService
      .odataStr(this.state, this.oDataStr)
      .subscribe((response) => {
        this.isLoading = false;
        this.gridView = {
          data: response.value,
          total: response['@odata.count'],
        };
      });
  }

  cancel() {
    this.dialog.close(false);
  }

  select(e: any) {
    this.dialog.close(e.dataItem);
  }

  dataStateChange(state: State) {
    this.isLoading = true;
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.refreshList();
  }
}
