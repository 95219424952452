import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  expanded = false;

  constructor(public authService: AuthService) {
    // Expanded default
    var expandedStr = localStorage.getItem(LocalStorage.SIDENAV_EXPANDED);
    this.expanded = expandedStr == null || expandedStr == 'true';
  }

  ngOnInit(): void {}

  onExpandedChange(e: any) {
    localStorage.setItem(LocalStorage.SIDENAV_EXPANDED, e.toString());
  }
}
