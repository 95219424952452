<div class="ss-sidenav-main" [class.ss-sidenav-main-mini]="!expanded">
  <!-- HEADER INFO -->

  <div
    class="ss-sidenav-header-container"
    routerLink="/auth/home"
    routerLinkActive="selected"
  >
    <div *ngIf="locale === 'fr'" class="ss-sidenav-header-logo ss-sidenav-hover-pointer"></div>
    <div *ngIf="locale === 'en'" class="ss-sidenav-header-logo-en ss-sidenav-hover-pointer"></div>

  </div>

  <!-- USER INFO : CLIENT_EMPLOYE -->
  <div
    class="ss-sidenav-user-container"
    *ngIf="authService.userRole !== 'GLOBAL_ADMIN'"
  >
    <div
      *ngIf="customerInfo.mainLogoURI && customerInfo.mainLogoURI.length > 0"
      class="ss-sidenav-user-logo"
      [style]="'background-image: url(' + customerInfo.mainLogoURI + ')'"
    ></div>
    <div class="ss-sidenav-user-customer">{{ customerInfo.customerName }}</div>
    <div class="ss-sidenav-user-name"> {{ userFullName }} </div>
    <div class="ss-sidenav-user-name"> #{{ userName }}</div>
  </div>

  <div
    class="ss-sidenav-user-container"
    *ngIf="
      authService.userRole == 'GLOBAL_ADMIN' ||
      authService.userRole == 'SUPERVISEUR'
    "
  >
    {{ "module.user.mainUserType." + authService.userRole | translate }}
  </div>

  <!-- MENU CONTAINER -->

  <div class="ss-sidenav-menu-container">
    <!-- HOME -->
    <a
      class="ss-sidenav-menu-item"
      routerLink="/auth/home"
      routerLinkActive="selected"
      mat-button
    >
      <mat-icon>home</mat-icon>
      <span>{{ "layout.sidenav.button_homePage" | translate }}</span>
    </a>
      <!-- CUSTOMER -->
    <a
      *ngIf="authService.userRole == 'GLOBAL_ADMIN'"
      class="ss-sidenav-menu-item"
      routerLink="/customer"
      routerLinkActive="selected"
      mat-button
    >
      <mat-icon>contact_emergency</mat-icon>
      <span>{{ "layout.sidenav.button_customer" | translate }}</span>
    </a>

    <!-- USER GROUP -->
    <a
      *ngIf="
        authService.userRole == 'GLOBAL_ADMIN' ||
        authService.userRole == 'SUPERVISEUR'
      "
      class="ss-sidenav-menu-item"
      routerLink="/user-group"
      routerLinkActive="selected"
      mat-button
    >
      <mat-icon>groups</mat-icon>
      <span>{{ "layout.sidenav.button_user_group" | translate }}</span>
    </a>

    <!-- REPORT -->

    <a
      *ngIf="
        authService.userRole == 'GLOBAL_ADMIN' ||
        authService.userRole == 'SUPERVISEUR'
      "
      class="ss-sidenav-menu-item"
      routerLinkActive="selected"
      routerLink="/report"

      mat-button
    >
      <mat-icon>pie_chart</mat-icon>
      <span>{{ "layout.sidenav.button_report" | translate }}</span>
    </a>

    <!-- ORDER -->

    <a
      *ngIf="
        authService.userRole == 'GLOBAL_ADMIN' ||
        authService.userRole == 'SUPERVISEUR' ||
        authService.userRole == 'CLIENT_EMPLOYE'
      "
      class="ss-sidenav-menu-item"
      routerLink="/order"
      routerLinkActive="selected"
      mat-button
    >
      <mat-icon>receipt</mat-icon>
      <span>{{ "layout.sidenav.button_order" | translate }}</span>
    </a>

    <!-- USER  -->

    <a
      *ngIf="
        authService.userRole == 'GLOBAL_ADMIN' ||
        authService.userRole == 'SUPERVISEUR'
      "
      class="ss-sidenav-menu-item"
      routerLink="/user"
      routerLinkActive="selected"
      mat-button
      ><mat-icon>person</mat-icon
      ><span>{{ "layout.sidenav.button_user" | translate }}</span></a
    >

    <!-- PRODUCT -->

    <a
      *ngIf="authService.userRole == 'GLOBAL_ADMIN'"
      class="ss-sidenav-menu-item"
      routerLink="/product"
      routerLinkActive="selected"
      mat-button
    >
      <mat-icon>medication</mat-icon>
      <span>{{ "layout.sidenav.button_product" | translate }}</span>
    </a>
    <div
      *ngIf="authService.userRole != 'GLOBAL_ADMIN'"
      class="ss-sidenav-spacer"
    ></div>

    <!-- CATALOG -->

    <a
      *ngIf="authService.userRole !== 'GLOBAL_ADMIN'"
      class="ss-sidenav-menu-item ss-sidenav-menu-item-catalog"
      routerLink="/catalog"
      routerLinkActive="selected"
      mat-button
    >
      <mat-icon>menu_book</mat-icon>
      <span>{{ "layout.sidenav.button_catalog" | translate }}</span>
    </a>

  </div>
</div>
