import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor,HttpResponse,HttpErrorResponse,HttpStatusCode,} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ErrorHandlerHttp } from 'src/app/core/error/error-handler-http.model';

import { AuthService } from 'src/app/core/auth/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  dialogRef: any;

  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((errorResponse: HttpErrorResponse) => {

        console.log('error interceptor : catch error');
        console.log(errorResponse);

        let httpError = {
          key: '',
          errorTitle: '',
          errorMessage: '',
          fullHttpErrorReponse: errorResponse,
          statusCode: errorResponse.status,
        } as ErrorHandlerHttp;

        switch (errorResponse.status) {
          case HttpStatusCode.Ok:
          case HttpStatusCode.NoContent:
          case HttpStatusCode.NotModified: {
            // exécution réussie
            break;
          }
          case HttpStatusCode.Unauthorized: {
            httpError.key = 'error.HTTP_STATUT_UNAUTHORIZED';
            if (
              errorResponse.error &&
              errorResponse.error.errorCode == 'USER_MUST_CHANGE_PASSWORD'
            ) {
              this.router.navigate([
                '/auth/reset-password',
                {
                  changePasswordToken: errorResponse.error.changePasswordToken,
                  userId: errorResponse.error.userId,
                },
              ]);
            } else {
              this.router.navigate(['/auth']);
            }
            break;
          }
          case HttpStatusCode.Conflict: {
            httpError.key = 'error.HTTP_STATUT_CONFLICT';
            break;
          }
          case HttpStatusCode.InternalServerError: {
            if (this.router.url != '/auth')
              httpError.key = 'error.HTTP_STATUT_INTERNALSERVERERROR';
            break;
          }
          case HttpStatusCode.Forbidden: {
            httpError.key = 'error.HTTP_STATUT_FORBIDDEN';
            break;
          }
          case HttpStatusCode.ServiceUnavailable: {
            httpError.key = 'error.HTTP_STATUT_SERVICEUNAVAILABLE';
            break;
          }
          case HttpStatusCode.NotFound: {
            httpError.key = 'error.HTTP_STATUT_NOTFOUND';
            break;
          }
          case HttpStatusCode.BadRequest: {
            httpError.key = 'error.HTTP_STATUT_BADREQUEST';
            break;
          }
          case 0: {
            httpError.key = 'error.HTTP_STATUT_0';
            break;
          }
          default: {
            httpError.key = 'error.HTTP_STATUT_UNKNOWN';
            break;
          }
        }

        // rechercher un code dans la reponse
        if (errorResponse.error && errorResponse.error.title) {
          let title = errorResponse.error.title;
          httpError.key = 'error.' + title;
          if (title == 'VALIDATION_ERROR_INVALID_DATA') {
            let validationErrors =
              errorResponse.error.errors.VALIDATION_ERROR_INVALID_DATA;
            if (validationErrors.length > 0)
              httpError.key = 'error.' + validationErrors[0].split('|')[0];
          }
        }

        return throwError(httpError);
      })
    );
  }
}
