import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridService } from 'src/app/shared/grid.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../user/user.service';
import { DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-supervisor-popup',
  templateUrl: './supervisor-popup.component.html',
  styleUrls: ['./supervisor-popup.component.scss'],
})
export class SupervisorPopupComponent extends DialogContentBase implements OnInit {
  title = '';
  button_cancel = '';
  isLoading = false;
  gridView!: GridDataResult;
  id!: number;
  userSub: Subscription
  pageable = {
    pageSizes: this.gridService.pageSizes,
  };

  public state: State = {
    take: this.gridService.take,
    skip: 0,
  };

  constructor(
    public override dialog: DialogRef,
    private gridService: GridService,
    private userService: UserService
  ) {
    super(dialog);
    // api call odata
    this.userService.odata(this.state).subscribe();
    // subscribe to data
    this.isLoading = true;
    this.userSub = this.userService
      .odataStr(
        { take: 25, skip: 0 },
        "$select=userId,userName,userFirstName,userLastName&$filter=(isEnabled eq true and mainUserType eq 'SUPERVISEUR')"
      )
      .subscribe((response:any) => {
        this.isLoading = false;
        this.gridView = {
          data: response.value,
          total: response['@odata.count']
        }
      });
  }

  ngOnInit(): void {}

  cancel() {
    this.dialog.close(false);
  }

  select(e: any) {
    this.dialog.close(e.dataItem);
  }

  dataStateChange(state: State) {

    let _state = state;

    _state.filter?.filters.push({
      field: "isEnabled",
      operator: "eq",
      value: true
    });

    _state.filter?.filters.push({
      field: "mainUserType",
      operator: "eq",
      value: 'SUPERVISEUR'
    });

    this.isLoading = true;
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.userService.odata(this.state).subscribe((response) => {
      this.isLoading = false;
      this.gridView.data = response.value
    });
  }
}
