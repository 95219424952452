import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationService } from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';
import { TranslationManagementService } from '../core/translation/translate.service';
import { DialogService,DialogRef,DialogCloseResult } from '@progress/kendo-angular-dialog';

import { PopAlertComponent } from './component/pop-alert/pop-alert.component';
import { PopConfirmComponent } from './component/pop-confirm/pop-confirm.component';
import { CompositeFilterDescriptor, FilterDescriptor, State, toODataString } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private tmService: TranslationManagementService
  ) {}

  // RETURN FRENCH OR ENGLISH COLUMN

  translateGridColumn(colFr: string, colEn: string) {
    return this.translateService.currentLang == 'fr' ? colFr : colEn;
  }

  // FORMAT DATE

  formatDateToString(value: Date): string {
    //var date = moment.isMoment(value) ? value.toDate() : value;
    //2022-06-16T13:32:03.86-04:00
    var date = value;
    var strDate =
      date.getFullYear() +
      '-' +
      this.pad(date.getMonth() + 1, 2) +
      '-' +
      this.pad(date.getDate(), 2) +
      'T' +
      this.pad(date.getHours(), 2) +
      ':' +
      this.pad(date.getMinutes(), 2) +
      ':' +
      this.pad(date.getSeconds(), 2) +
      '.00';
    return strDate;
  }

  formatStringToDate(value: string) {
    if (value) {
      var date = new Date();
      var y = +value.substring(0, 4);
      var m = +value.substring(5, 7) - 1;
      var d = +value.substring(8, 10);
      date = new Date(y, m, d);
      return date;
    } else return new Date(0);
  }

  // PAD

  pad(num: number, size: number) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  // NOTIFICATION

  notify(key: string, typeStyle: any, hideAfterMS:number=3000) {
    let content = this.translateService.instant(key);
    this.notificationService.show({
      content: content,
      cssClass: 'ss-notification',
      animation: { type: 'slide', duration: 300 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: typeStyle, icon: true }, // style = 'none'|'success'|'error'|'warning'|'info'
      hideAfter: hideAfterMS,
    });
  }

  // DIALOG | ALERT

  private dialog!: DialogRef;

  alert(title: string, message: string, cssClass:string|null = null) {
    // open dialog
    this.dialog = this.dialogService.open({
      content: PopAlertComponent,
      minWidth: 400,
      maxWidth: 700,
      cssClass: cssClass
    });
    // set default values
    const dialogData = this.dialog.content.instance as PopAlertComponent;
    dialogData.title = title;
    dialogData.message = message;
    // subscribe to response
    this.dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // cancel
        return true;
      } else {
        // submit
        return false;
      }
    });
  }

  // DIALOG | CONFIRM

  public confirm(title: string, message: string, yes: string, no: string): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: PopConfirmComponent,
        minWidth: 400,
        maxWidth: 700,
      });
      // set default values
      const dialogData = this.dialog.content.instance as PopConfirmComponent;
      dialogData.title = title;
      dialogData.message = message;
      dialogData.button_yes = yes;
      dialogData.button_no = no;
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(true);
        }
      });
    });
    return confirmObservable;
  }

  public alertAwaitable(title: string, message: string): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: PopAlertComponent,
        minWidth: 400,
      });
      // set default values
      const dialogData = this.dialog.content.instance as PopAlertComponent;
      dialogData.title = title;
      dialogData.message = message;
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(true);
        }
      });
    });

    return confirmObservable;
  }

  // PASSWORD VALIDATION

  validatePassword(
    newPassword: string,
    confirmPassword: string,
    oldPassword: string,
    modifySelf: boolean
  ): string {
    if (modifySelf && oldPassword == '')
      return 'module.auth.reset-password.error_confirm_old_password';
    else if (newPassword.length < 8)
      return 'module.auth.reset-password.error_too_short';
    else if (newPassword != confirmPassword)
      return 'module.auth.reset-password.error_not_the_same';
    else if (/[A-Z]/.test(newPassword) == false)
      return 'module.auth.reset-password.error_uppercase';
    else if (/[a-z]/.test(newPassword) == false)
      return 'module.auth.reset-password.error_lowercase';
    else if (/[0-9]/.test(newPassword) == false)
      return 'module.auth.reset-password.error_number';
    else if (
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword) == false
    )
      return 'module.auth.reset-password.error_special_char';
    else return '';
  }

  public validateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      return true;
    else return false;
  }

  public getPropertyTranslated(
    frenchProp: string,
    englishProp: string
  ): string {
    let local = this.translateService.currentLang.toLowerCase();

    if (local === 'en') {
      return englishProp;
    } else {
      return frenchProp;
    }
  }

  public putDollarSignByLocal(price: string): string {
    let local = this.translateService.currentLang.toLowerCase();

    if(price === "Infinity") {
      return 'N/A'
    }

    if (local === 'en') {
      return `$${price}`;
    } else {
      return `${price} $`.replace('.', ',');
    }
  }

  private makeCompositeFilterDescriptorCaseInsensitive(value:CompositeFilterDescriptor):CompositeFilterDescriptor  {
    let _filter = value.filters
    for (const filter of _filter) {
      this.filterRecursive(filter)
    }

    return {
      logic: value.logic,
      filters: _filter
    }

  } 
  /**
   * Permet de creuser récursivement pour que les FilterDescriptor au 
   * sein du ou des CompositeFilterDescriptor se font rajouter le case insensitivity
   * @param filter 
   */
  private filterRecursive(filter: CompositeFilterDescriptor | FilterDescriptor):void {
    const caseSensitiveOperators : string[] = ['contains','eq', 'neq','startswith','endswith','doesnotcontain']
    // Certain champs de type enum saute et on doit donc les exclures
    let fieldListToAvoid = ['orderGlobalStatus']
    
    if(this.instanceOfFilterDescriptor(filter)) {
      let field = `${filter.field}`
      if (caseSensitiveOperators.indexOf(filter.operator.toString()) !== -1)
      {
        if(field && fieldListToAvoid.indexOf(field) > -1) {
          filter.ignoreCase = false
        }
        else {
          filter.ignoreCase = true
          if(typeof(filter.value) === 'string') {
            filter.value = filter.value.toLowerCase()
          }
        } 
      }
    }
    else {
      for (const subFilter of filter.filters) {
        this.filterRecursive(subFilter);
      }
    }
  }

  /*Permet de savoir si un objet qui hérite de l'interface FilterDescriptor*/
  private instanceOfFilterDescriptor(_obj:FilterDescriptor | CompositeFilterDescriptor): _obj is FilterDescriptor {
    return 'field' in _obj
  }

  public getODataFullString(state: State, oDataString: string) {
    // merge oDataString Filter and grid Filter, remove from state
    let newState:State = {
      take: state.take,
      skip: state.skip,
      sort: state.sort,
      group: state.group,
    };

    let filterStr:string = ''

    // S'il y a un ou des filtres d'appliqués alors on les rend case insensitive
    if(state.filter) {
      filterStr = toODataString({ filter: this.makeCompositeFilterDescriptorCaseInsensitive(state.filter) });
    }
    else {
      filterStr = toODataString({ filter: state.filter });
    }
    // Corrige un edge case où la requête formée pouvait avoir plusieurs filter statement.
    if (filterStr) {
      filterStr = filterStr.replace('$filter=', '');
      filterStr = filterStr.replace(/T00:00:00.000Z/g, '');
      if (oDataString) {
        if (oDataString.indexOf('$filter') != -1) {
          oDataString += ' and ' + filterStr;
        } else {
          oDataString += '&$filter=' + filterStr;
        }
      }
    }
    let data = oDataString + '&' + toODataString(newState);
    return data
  }

  public makeStateCaseInsensitive(state:State):State {
    let _state = state;
    if(state.filter) {
      _state.filter = this.makeCompositeFilterDescriptorCaseInsensitive(state.filter)
    }

    return state
  }

  public transformDateLocale(dateInStringFormart:string): string {
    let _date = new Date(`${dateInStringFormart}:00:00.000`)
    return this.tmService.formatDateToLocale(_date)
  }
}
