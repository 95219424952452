import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { GridDataResult} from '@progress/kendo-angular-grid';

import { UserGroupService } from '../user-group.service';
import { GridService } from 'src/app/shared/grid.service';

@Component({
  selector: 'app-user-group-popup',
  templateUrl: './user-group-popup.component.html',
  styleUrls: ['./user-group-popup.component.scss'],
})
export class UserGroupPopupComponent extends DialogContentBase {
  title = '';
  button_cancel = '';

  isLoading = false;
  gridView!: GridDataResult;
  id!: number;

  pageable = {
    pageSizes: this.gridService.pageSizes,
  };

  public state: State = {
    take: this.gridService.take,
    skip: 0,
  };

  constructor(
    public override dialog: DialogRef,
    private gridService: GridService,
    private userGroupService: UserGroupService
  ) {
    super(dialog)
    // api call odata
    this.userGroupService.odata(this.state).subscribe();
    // subscribe to data
    this.isLoading = true;
    this.userGroupService.userGroupsOdata.subscribe((response) => {
      this.isLoading = false;
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }

  cancel() {
    this.dialog.close(false);
  }

  select(e: any) {
    this.dialog.close(e.dataItem);
  }

  dataStateChange(state: State) {
    this.isLoading = true;
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.userGroupService.odata(this.state).subscribe((response) => {
      this.isLoading = false;
    });
  }
}
