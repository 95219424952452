import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CustomerService } from 'src/app/module/customer/customer.service';
import { TranslationManagementService } from 'src/app/core/translation/translate.service';
import { UserService } from 'src/app/module/user/user.service';
import { GlobalDataService } from 'src/app/core/global-data/global-data.service';
import { Customer } from 'src/app/module/customer/customer.model';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})

export class SidenavComponent implements OnInit, OnDestroy {
  @Input() expanded: boolean;

  userName = this.authService.userName;
  userFullName:string = ''
  locale:string = 'fr'
  customerInfo = new Customer()

  customerIdSub!:Subscription
  langSub!:Subscription
  userSub!:Subscription
  httpSub:Subscription | null = null;

  constructor(
    public authService: AuthService,
    public customerService: CustomerService,
    private translateService: TranslationManagementService,
    private userService: UserService,
    private globalDataService: GlobalDataService
  ) {
    this.expanded = true;
  }

  ngOnInit() {

    this.locale = this.translateService.getCurrentLang();

    this.langSub = this.translateService.onLangChange.subscribe( langEvent => {
      this.locale = langEvent.lang
    });

    this.userSub = this.userService.read(this.authService.userId).subscribe( user => {
      this.userFullName = user.userFullName
    });

    this.customerIdSub = this.globalDataService.currentCustomerId
    .subscribe( id => {
      if (this.customerInfo.customerId !== id && id !== 0) {
        this.getCustomerInfo(id);
      }
    })

  }

  ngOnDestroy(): void {
    this.customerIdSub.unsubscribe();
    this.langSub.unsubscribe();
    this.userSub.unsubscribe();

    if(this.httpSub !== null) {
      this.httpSub.unsubscribe();
    }

  }


  private getCustomerInfo(customerId:number) {
    this.httpSub = this.customerService.read(customerId)
    .pipe(
      take(1)
    )
    .subscribe( (customer) => {
      this.customerInfo = customer;
    });
  }

}
