<kendo-dialog-titlebar (close)="cancel()">
  <div style="font-size: 18px; line-height: 1.3em">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->
<div class="ss-dialog-content">
  <div class="ss-pop-confirm-message">{{ message | translate }}</div>
</div>
<!-- BUTTONS -->
<kendo-dialog-actions>
  <button
    kendoButton
    themeColor="primary"
    icon="close"
    fillMode="flat"
    (click)="cancel()"
  >
    {{ button_no | translate }}
  </button>
  <button kendoButton themeColor="primary" icon="check" (click)="confirm()">
    {{ button_yes | translate }}
  </button>
</kendo-dialog-actions>
