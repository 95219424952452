import { Component, OnInit, Input, Output, DoCheck, OnDestroy, EventEmitter } from '@angular/core';
import { Product } from '../product.model';
import { ProductProduct } from '../product-product.model';
import { CvlField } from 'src/app/core/product-information-management/product-management-information-models/cvl-field.models';
import { CvlModified } from '../cvl-modified.model';
import { CatalogService } from '../catalog.service';
import { UtilService } from 'src/app/shared/util.service';
import { ProductInformationManagementService } from 'src/app/core/product-information-management/product-information-management.service';
import { Subscription, Observable, take, combineLatest } from 'rxjs';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})
export class ItemDetailComponent implements OnInit, DoCheck, OnDestroy {
  @Input() product: Product = new Product(
    0,
    '0',
    0,
    new ProductProduct(0, 0, [], '', '', '', '', '', '', 0, 0, '', '')
  );
  @Input() popupMode: boolean = false;
  @Input() quantity: number = 1;
  @Input() caller: string = '';
  @Output() addToCartClicked: EventEmitter<{
    item: any;
    productId: any;
    quantitySelected: any;
    productTitleFr: any;
    productTitleEn: any;
  }> = new EventEmitter();

  quantitySelected: number = 1;
  itemProperties: string[] = [];
  items: any[] = [];
  fileteredItems: any[] = [];
  isDisabledAddToCart: boolean = false;
  arrOfNames: string[] = [];
  arrOfValues: string[] = [];
  arrOfNamesNotSelected: string[] = [];
  item: any = null;
  canSeePrice: boolean = false;
  Cvls_data: CvlModified[] = [];

  listOfAllPossibleFilteringsProperties: string[] = [];
  isLoading = true;
  dataSub!: Subscription;
  canSeePriceSub!: Subscription;
  cvlfields: CvlField[] = [];
  imageURI:string = "";

  constructor(
    private catalogService: CatalogService,
    private utilService: UtilService,
    private pimService: ProductInformationManagementService
  ) {}

  ngOnInit(): void {
    this.quantitySelected = this.quantity;

    this.listOfAllPossibleFilteringsProperties =
      this.catalogService.getListOfAllPossibleFilteringProperties();

    let listOfFilteringProperties: string[] = [];

    let rawData = this.catalogService.getProductItems(this.product.productId).pipe(take(1));
    let cvlFields = this.pimService.getCvlFields();

    let asyncData = combineLatest([rawData, cvlFields]);

    this.dataSub = asyncData.subscribe( ([rawData, cvlFields]) => {
      this.cvlfields = cvlFields;

      let allProperties: string[] = [];
      let productItems: any[] = rawData.productItems;

      // Il faut placer toutes les propriétés des productItems dans le tableau et faire un distinct
      for (const item of productItems) {
        let x = Object.keys(item);
        allProperties = allProperties.concat(x);
      }

      //On fait le distinct
      allProperties = [...new Set(allProperties)];

      //On filtre les attributs selon s'ils sont présents dans la liste des choix possibles
      let possibleProperties = allProperties.filter( (prop: string) =>
        this.listOfAllPossibleFilteringsProperties.indexOf(prop) !== -1
      );

      // Il faut vérifier si les propriétés sont non nulles dans les items
      for (const prop of possibleProperties) {
        let values: any[] = [];

        for (const item of productItems) {
          values.push(item[prop]);
        }

        values = [...new Set(values)];

        //Si on rentre dans le if alors les valeurs sont non nulles et on l'ajoute la propriété
        if ((values.length === 1 && values[0] === null) === false) {
          listOfFilteringProperties.push(prop);
        }
      }

      let listOfProductItems: any[] = [];

      for (let i = 0; i < productItems.length; i++) {
        let item = productItems[i];

        let objToPush: any = new Object();

        objToPush.productItemId = item.productItemId;
        objToPush.productItemNumber = item.productItemNumber;
        objToPush.productItemCustomerPrice = 0;
        objToPush.productItemTitleEn = item.productItemTitleEn;
        objToPush.productItemTitleFr = item.productItemTitleFr;
        objToPush.productItemMainImageUrl = item.productItemMainImageUrl;

        for (let y = 0; y < listOfFilteringProperties.length; y++) {
          let key = listOfFilteringProperties[y];
          objToPush[key] = item[key] ? item[key] : 'N.A.';
        }

        listOfProductItems.push(objToPush);
      }

      //Fail safe en cas d'erreur où il est impossible de distinguer entre les product item items via les filtering properties
      if (
        listOfProductItems.length > 1 &&
        listOfFilteringProperties.length === 0
      ) {
        let title = "module.catalog.warning-popup.title-error";
        let msg = "module.catalog.warning-popup.msg-failed-product-items";
        this.utilService.alert(title, msg);
        return;
      }

      this.itemProperties = listOfFilteringProperties;
      this.items = listOfProductItems;

      let ids: number[] = this.items.map((x: any) => x.productItemId);

      this.catalogService.getProductItemsPrice(ids).pipe(take(1)).subscribe((res2: any) => {
        for (const _item of res2) {
          this.items.filter(
            (x: any) => x.productItemId === _item.productItemId
          )[0].productItemCustomerPrice = _item.lastInforCSDPricing;
        }
        this.isLoading = false;
      });

      //Fix pour les produit qui ne contient qu'un seul item
      if (this.items.length === 1) {
        this.item = this.items[0];
      }

      listOfFilteringProperties.forEach((prop: string) => {
        this.fileteredItems.push({
          propName: prop,
          value: null,
        });

        this.pimService.getOneCvl(prop).subscribe( (_cvlData) => {

          let obj:CvlModified = {
            safestockPropname: prop,
            cvlData: _cvlData.cvlData
          }
          this.Cvls_data.push(obj)

        })
      });
    });

    this.canSeePriceSub = this.catalogService.canSeePrice().subscribe( res => {
      this.canSeePrice = res
    });
  }

  ngDoCheck(): void {
    this.arrOfNames = [];
    this.arrOfValues = [];
    this.arrOfNamesNotSelected = [];

    for (let i = 0; i < this.fileteredItems.length; i++) {
      if (this.fileteredItems[i].value != null) {
        this.arrOfValues.push(this.fileteredItems[i].value);
        this.arrOfNames.push(this.fileteredItems[i].propName);
      } else {
        this.arrOfNamesNotSelected.push(this.fileteredItems[i].propName);
      }
    }

    //logique pour savoir si le bouton add to cart doit être disabled ou non
    if (this.arrOfNames.length > 0) {
      if (this.arrOfNamesNotSelected.length > 0) {
        this.isDisabledAddToCart = true;
      } else {
        this.isDisabledAddToCart = false;

        //logique de sélection pour trouver l'item correspondant aux choix
        let arrOfItems = this.items;
        for (const name of this.arrOfNames) {
          let value = this.arrOfValues[this.arrOfNames.indexOf(name)];
          arrOfItems = arrOfItems.filter((x) => x[name] === value);
        }
        this.item = arrOfItems[0];
      }
    } else {
      this.isDisabledAddToCart = true;
    }

    //Fix pour les produit qui ne contient qu'un seul item
    if (this.item !== undefined && this.item !== null) {
      this.isDisabledAddToCart = false;
    }

    //s'il y a un problème avec le chargement de prix pour cette item alors on lock le bouton
    if (this.item && this.item.productItemCustomerPrice === 0) {
      this.isDisabledAddToCart = true;
    }

    this.imageURI = this.item?.productItemMainImageUrl ?? this.product.product.productMainImageUrl;

  }

  ngOnDestroy(): void {
    this.dataSub.unsubscribe();
    this.canSeePriceSub.unsubscribe();
  }

  public sortArrayOfPropertiesValues(propertyName: string): string[] {
    if (this.itemProperties.indexOf(propertyName) === -1) {
      throw 'Error, property name not part of the given list of properties';
    }

    let cvl = this.Cvls_data.filter(x => x.safestockPropname === propertyName)[0]

    let listOfProps: string[] = [];

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      listOfProps.push(item[propertyName]);
    }

    let props_set: string[] = [...new Set(listOfProps)];
    try {
      props_set.sort((a, b) => {

        let ElementsA = cvl.cvlData.filter((x) => x.key === a);
        let elementsB = cvl.cvlData.filter((x) => x.key === b);

        if (ElementsA.length > 0 && elementsB.length > 0){
          let varA = ElementsA[0].index;
          let varB = elementsB[0].index;
          let Resultat = varA - varB;
          return Resultat;
        }
        else
          return 0;
      });
    } catch (error) {

    }


    return props_set;

  }

  public buttonSelected(propertyName: string, propertyValue: string) {
    let item = this.fileteredItems.filter(
      (p) => p.propName === propertyName
    )[0];
    item.value = propertyValue;
  }

  public checkIfIsOnlyButtonInProperty(propName: string): boolean {
    let listOfProps: string[] = [];

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      listOfProps.push(item[propName]);
    }

    let values: string[] = [...new Set(listOfProps)];

    if (values.length === 1) {
      this.buttonSelected(propName, values[0]);
      return true;
    } else {
      return false;
    }
  }

  public checkIfButtonIsActive(propertyName: string, propertyValue: string): boolean {
    let index = this.arrOfNames.indexOf(propertyName);

    if (index === -1) {
      return false;
    }
    else if (this.arrOfValues[index] === propertyValue) {
      return true;
    }
    else {
      return false;
    }
  }

  public translate(french: string, english: string): string {
    return this.catalogService.getPropertyTranslated(french, english);
  }

  public transformPropName(propName: string): Observable<string> {
    return this.pimService.transformSafestockPropNameToPimTranslation(propName);
  }

  public transformPopValueToName(propName: string,propValue: any): string {
    let myReturnValue = propValue;

    //on va aller chercher la collection CVL qui correspond à notre CVL ici
    let MyCvls = this.Cvls_data.filter(x => x.safestockPropname === propName);

    //si on trouve notre CVL...
    if (MyCvls.length > 0 ) {
      let cvl = MyCvls[0]; //on prend le 1er (c'est la clé)

      //on va chercher la liste de VALUES qui correspond à notre value à nosu (du CVL...exemple,.le value de couleur sera '02'...on veut la description)
      let filteredCVlValues = cvl.cvlData.filter(x => x.key === propValue);

      //si on trouve un CVL values qui correspond..on retourne sa valeur.. (la description)
      if (filteredCVlValues.length > 0) {
        let _data = filteredCVlValues[0];
        myReturnValue = this.translate(_data.value.fr, _data.value.en);
      }
    }

    return myReturnValue;


    //return this.pimService.transformPopValueToName(propName, propValue);
  }

  public addToCart() {
    this.addToCartClicked.emit({
      item: this.item,
      productId: this.product.productId,
      quantitySelected: this.quantitySelected,
      productTitleFr: this.product.product.productTitleFr,
      productTitleEn: this.product.product.productTitleEn
    });
  }

  public navigateToCart() {
    this.catalogService.navigateToCart();
  }


  public getPrice(): string {
    if (this.item != undefined && this.item != null) {

      return this.catalogService.putDollarSign(
        (this.item.productItemCustomerPrice * this.quantitySelected).toFixed(2)
      );
    }
    // si aucun item de choisi alors affiche le plus petit.
    else {
      let prices = this.items.map(x => x.productItemCustomerPrice).sort( (a,b) => a - b );

      if(prices.length < 1) {
        return this.catalogService.putDollarSign('0');
      }

      return this.catalogService.putDollarSign(prices[0]);
    }
  }

  public IsAnUrlLink(paramValToTest?:string | null):boolean
  {
    if (!paramValToTest || paramValToTest === undefined || paramValToTest === null || paramValToTest.length < 4)
      return false;

    if (paramValToTest.substring(0,4).toLowerCase() === "http")
      return true

    return false;
  }

}
