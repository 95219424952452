<!-- TITLE BAR -->

<kendo-dialog-titlebar (close)="cancel()">
  <div style="font-size: 18px; line-height: 1.3em">
    {{ "module.catalog.add-to-cart-popup.title" | translate }}
  </div>
</kendo-dialog-titlebar>

<!-- CONTENT -->

<div class="ss-popup-container ss-popup-content-margin">
  <app-item-detail
    [quantity]="data.quantity"
    [popupMode]="data.popupMode"
    [product]="data.product"
    [caller]="data.caller"
    (addToCartClicked)="handleAddToCardClickedEvent($event)"
  ></app-item-detail>
</div>
