import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { Router } from '@angular/router';
import { GlobalDataService } from 'src/app/core/global-data/global-data.service';
import { UtilService } from 'src/app/shared/util.service';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { CartLine } from 'src/app/core/global-data/global-data-models/cart-line.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  langue = this.translate.currentLang
  remainingBudget:number = 0;
  canSeeRemainingBudget: boolean = false;
  logState:boolean = false
  numberOfItemsInCart:number = 0
  showCartButton = false;
  sub:Subscription
  langSub: Subscription
  @Output() toggleSidenav = new EventEmitter();

  ngOnInit(): void {
    this.showCartButton = this.authService.userRole != 'GLOBAL_ADMIN';
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
    this.langSub.unsubscribe()
  }

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private utilService: UtilService
  ) {

    // Le calcul du budget restant est affecté par le user order overview et les cart items alors
    // le tour de passe passe est de subscribe à c'est deux là en plus du cart pour avoir un header à jour  
    let uoo = this.globalDataService.userOrderOverview.asObservable();
    let cart = this.globalDataService.cart.asObservable();
    let cartItems = this.globalDataService.cartItems.asObservable();    
    let asyncData = combineLatest([uoo,cart,cartItems])

    this.sub = asyncData.subscribe(([uoo,cart,cartItems]) => {
      this.numberOfItemsInCart = this.getNumberOfCartItems(cart)
      this.globalDataService.canUserSeeHeaderBudget().subscribe( res => {
        this.canSeeRemainingBudget = res
      });
      this.globalDataService.getRemainingBudget().subscribe( budgetLeft => {
        this.remainingBudget = budgetLeft;
      })
    })

    this.langSub = this.translate.onLangChange.subscribe( event => {
      this.langue = event.lang;
    })

  }

  selectLangue(langue: string) {
    this.langue = langue;
    this.translate.use(langue)
  }

  logout() {
    this.logState = false;
    this.globalDataService.clearGlobalData();
    this.authService.logout(true).subscribe();
  }

  goToCart() {
    if(this.authService.userRole === 'SUPERVISEUR') {
      this.router.navigate(['/cart/supervisor']);
    }
    else {
      this.router.navigate(['/cart']);
    }
  }

  private getNumberOfCartItems(cart:CartLine[]):number {
    let numberOfItemsInCart = 0

    for (const cartLine of cart) {
      numberOfItemsInCart += cartLine.quantity
    }
    return numberOfItemsInCart
  }
  
  public getDollorSignByLocale(budget:number):string {
    return this.utilService.putDollarSignByLocal(budget.toFixed(2));
  }
}
