import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { Customer } from './customer.model';
import { CustomerPopupComponent } from './customer-popup/customer-popup.component';
import { SupervisorPopupComponent } from './supervisor-popup/supervisor-popup.component';
import { UtilService } from 'src/app/shared/util.service';
import { CustomerItemCode } from './CustomerItemCode.model';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customers: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(
    1
  );
  public customer: ReplaySubject<Customer> = new ReplaySubject<Customer>(1);
  public customersOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  private dialog!: DialogRef;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private utilService: UtilService
  ) {}

  initOne() {
    this.customer.next({
      customerId: 0,
      customerName: '',
      isActiveInSafestock: false,
      customerProductXrefs: [],
      mainLogoURI: '',
      defaultShipToId: '',
      activateInforCustomerProductItemFetch:null
    });
  }

  list(): Observable<Customer[]> {
    let uri = `${apiUrl}customer/Customers`
    return this.http.get<Customer[]>(uri).pipe(
      mergeMap((response: any) => {
        let value = response.value
        this.customers.next(value);
        return of(value)
      })
    );
  }

  read(id: number): Observable<Customer> {
    return this.http
      .get<Customer>(apiUrl + 'customer/Customers/' + id, {})
      .pipe(
        tap((response: any) => {
          this.customer.next(response);
        })
      );
  }

  create(customer: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http.post<any>(apiUrl + 'customer/Customers/', customer).pipe(
      tap((response: any) => {
        //this.customer.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
        // TODO : refresh de la variable this.customers avec le nouveau record
      })
    );
  }

  update(customer: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .put<any>(apiUrl + 'customer/Customers/' + customer.customerId, customer)
      .pipe(
        tap((response: any) => {
          // this.customer.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
          // TODO : refresh de la variable this.customers avec le record a jour
        })
      );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {

    let _state = this.utilService.makeStateCaseInsensitive(state)
    let uri = `${apiUrl}customer/Customers?${toODataString(_state)}&$count=true`
    
    return this.http.get<Customer>(uri).pipe(
      tap((response: any) => {
        this.customersOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}customer/Customers?${oDataFullString}&$count=true`
    return this.http.get<any>(uri);
  }

  // UPLOAD LOGO

  //api/Customer/Customers/UploadCustomerLogo/59841
  uploadLogo(id: number, fileToUpload: File): Observable<any> {
    /*
    erreurs serveur possible:
    INVALID_FILE_CONTENTTYPE
    FILE_IMAGE_EXCEED_LIMIT_500KB
    */
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http
      .post(apiUrl + 'customer/Customers/UploadCustomerLogo/' + id, formData)
      .pipe(
        tap((response: any) => {})
      );
  }

  // POPUP CUSTOMER LIST

  public popup(): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: CustomerPopupComponent,
        minWidth: 400,
        height: 800,
        maxWidth: 900,
      });
      // set default values
      const dialogData = this.dialog.content.instance as CustomerPopupComponent;
      dialogData.title = 'module.customer.popup.title';
      dialogData.button_cancel = 'module.customer.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }

  public supervisorPopup(): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: SupervisorPopupComponent,
        minWidth: 400,
        maxHeight: 800,
        maxWidth: 900,
      });
      // set default values
      const dialogData = this.dialog.content.instance as SupervisorPopupComponent;
      dialogData.title = 'module.customer.popup.supervisorTitle';
      dialogData.button_cancel = 'module.customer.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }


  /**
   * Permet de déclancher le refresh 
   * @param id 
   * @returns 
   */
  public launchCustomerItemRefresh(id:number):Observable<any>{
      // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
      return this.http
      .post<any>(apiUrl + 'customer/CustomerProductItemXrefs/ForceRefreshAllCustomerProductNumber/' + id, null)
      .pipe(
        tap((response: any) => {
          // this.customer.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
          // TODO : refresh de la variable this.customers avec le record a jour
        })
      );

  }

  /**
   * Permet de déclancher le fetch de tous les codes produits-client d'un client en particulier
   * @param id 
   * @returns 
   */
   public getCustomerItemCodes(id:number):Observable<CustomerItemCode[]>{
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
    .get<any>(apiUrl + `customer/CustomerProductItemXrefs?$filter=customerId eq ${id} and CustomershipToId eq null and customerProductItemNumber ne null&$select=productItemId,customerProductItemNumber`)
    .pipe(
      mergeMap((response: any) => {
        let value = response.value
        return of(value)
      })
    ); 

}
}
