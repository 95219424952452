import { Injectable } from '@angular/core';

import { LocalStorage } from 'src/app/core/local-storage.enum';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  public pageSizes = [10, 25, 50, 100];
  public take = 25;

  constructor() {}

  setRowPerPages(state: any) {
    this.take = state.take;
    localStorage.setItem(LocalStorage.GRID_ITEM_PER_PAGE, state.take + '');
  }
}
