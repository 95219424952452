export class CartLine {

  public productId:number;
  public itemId:number;
  public quantity:number;

  constructor(productId:number, itemId:number, quantity:number) {
    this.productId = productId;
    this.itemId = itemId;
    this.quantity = quantity;
  }
}
