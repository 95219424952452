import { Component } from '@angular/core';
import { DialogService, DialogCloseResult, DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-pop-alert',
  templateUrl: './pop-alert.component.html',
  styleUrls: ['./pop-alert.component.scss'],
})
export class PopAlertComponent extends DialogContentBase {
  title = '';
  message = '';

  constructor(public override dialog: DialogRef) {
    super(dialog)
  }

  close() {
    this.dialog.close(false);
  }

  ok() {
    this.dialog.close(true);
  }
}
