import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilService } from 'src/app/shared/util.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private utilService: UtilService
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    console.log('*** ERROR HANDLER ***');

    if (error instanceof HttpErrorResponse) {
      // Server error
      console.log('SERVER ERROR');
      console.error(error);
    } else if (error instanceof Error) {
      // Client Error
      console.log('CLIENT ERROR');
      console.error(error);
    } else {
      // Unknown Error
      console.log('UNKOWN ERROR');
      console.error(error);
      let anyError = <any>error;
      if (anyError.key) this.utilService.notify(anyError.key, 'error');
    }
  }
}
