import { Injectable } from '@angular/core';
import { CvlField } from './product-management-information-models/cvl-field.models';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, mergeMap, BehaviorSubject, forkJoin, tap, catchError, retry, firstValueFrom, lastValueFrom } from 'rxjs';
import { Cvl } from './product-management-information-models/cvl.model';
import { LocalStorage } from '../local-storage.enum';
import { UtilService } from 'src/app/shared/util.service';

@Injectable({
  providedIn: 'root',
})

export class ProductInformationManagementService {

    private cvlFields:BehaviorSubject<CvlField[]> = new BehaviorSubject<CvlField[]>([])
    private cvlData:BehaviorSubject<CvlExtended[]> = new BehaviorSubject<CvlExtended[]>([])

    private BASE_URL = environment.apiUrl

    constructor(private http:HttpClient, private utilService:UtilService) {}

    /*CVL FIELDS SECTION*/
    private getAllCvlFields(): Observable<CvlField[]> {
        let uri = `${this.BASE_URL}Product/PIM/GetFieldInformation`
        return this.http.get<CvlField[]>(uri).pipe(
            tap((res) => {}),
            catchError( (err: HttpErrorResponse) => {
                throw err
            }),
            retry(2)
        )
    }

    /**
     * Initiliase les CVl Fields dans la mémoire du service, les fetch au besoin.
     * @returns Retourne tous cvl Fields
     */
    public initLoadCvlFields():Observable<CvlField[]> {
        let cvlFields: CvlField[] = [];
        let ls = localStorage.getItem(LocalStorage.CVL_FIELDS_DATA)

        if( ls !== null && ls.length > 0) {
            cvlFields = JSON.parse(ls!) as CvlField[]
            this.setCvlFields(cvlFields)
            return of(cvlFields)
        }
        else {
            return this.getAllCvlFields().pipe(tap( (_cvlFields:any) => {
                this.setCvlFields(_cvlFields)
            }))
        }
    }

    private setCvlFields(cvlFields:CvlField[]):void {
        this.cvlFields.next(cvlFields)
        localStorage.setItem(LocalStorage.CVL_FIELDS_DATA, JSON.stringify(cvlFields))
    }

    public getCvlFields(): Observable<CvlField[]> {
        return this.cvlFields.asObservable()
    }

    /*CVL DATA SECTION*/
    private getCvlData(cvlId:string): Observable<Cvl[]> {
        let uri = `${this.BASE_URL}Product/PIM/GetCVLData/${cvlId}`
        return this.http.get<Cvl[]>(uri).pipe(
            tap(() => {}),
            catchError( (err: HttpErrorResponse) => {
                throw err
            }),
            retry(2)
        )
    }
    /**
     * Initialise les CVL dans la mémoire du service
     * @returns Toutes les cvl stockées dans le localStorage 
     */
    public initLoadCvlData():Observable<CvlExtended[]> {
        let _cvl: CvlExtended[];
        let ls = localStorage.getItem(LocalStorage.CVL_DATA)

        if( ls !== null && ls.length > 0) {
            _cvl = JSON.parse(ls!) as CvlExtended[]
            this.setCvlData(_cvl)
            return of(_cvl)
        }
        else {
            this.setCvlData([])
            return of([])
        }
    }

    private setCvlData(cvlData:CvlExtended[]):void {
        this.cvlData.next(cvlData)
        localStorage.setItem(LocalStorage.CVL_DATA, JSON.stringify(cvlData))
    }

    /**
     * Méthode qui retourne une cvl ainsi que ses données.
     * 
     * Si elle ne possède pas la cvl demandée, la fetch et 
     * la rajoute à sa liste existente pour de future réutilisation 
     * @param safestockPropertyName 
     * @returns retourne la cvl ainsi que ses données
     */
    public getOneCvl(safestockPropertyName:string): Observable<CvlExtended> {
        return this.cvlFields.pipe( mergeMap( fields => {
            return this.cvlData.pipe( mergeMap( data => {
                //Met la première lettre en majuscule.
                let prop = this.putFirstCharacterInUppercase(safestockPropertyName)

                let cvlfield = fields.filter(y => y.safestockPropertyName === prop)[0]
                let cvl = data.filter(x => x.cvlId === cvlfield.cvlId)[0]

                if(cvl) {
                    return of(cvl)
                }
                else {
                    return this.getCvlData(cvlfield.cvlId).pipe( 
                        mergeMap( cvlData => {
                            let newCvl:CvlExtended = {
                                cvlId: cvlfield.cvlId,
                                cvlData: cvlData
                            }
                            
                            let _data = data
                            _data.push(newCvl)
                            this.setCvlData(_data)
                
                            return of(newCvl)
                        })
                    )
                }
            }))
        }))   
    }

    /**
     * Transforme le nom safestock de la propriété ainsi que sa valeur en traduction par le PIM
     * @param propName 
     * @param propValue 
     * @returns le nom traduit provenant du PIM
     */
    public transformPopValueToName(propName:string,propValue:any):Observable<string> {
        return this.getOneCvl(propName).pipe( mergeMap( (cvl) => {
            let val = cvl.cvlData.filter(x => x.key === propValue)[0].value
            return of(this.utilService.getPropertyTranslated(val.fr, val.en))
        }))
    }

    /**
     * Transforme le nom safestock de la propriété en valeur traduite par le PIM
     * @param safestockName 
     * @returns le nom traduit provenant du PIM
     */

    public transformSafestockPropNameToPimTranslation(safestockName:string): Observable<string> {
        return this.cvlFields.pipe( mergeMap( fields => {
            let prop = this.putFirstCharacterInUppercase(safestockName)
            let field = fields.filter( x => x.safestockPropertyName === prop)[0]

            return of(this.utilService.getPropertyTranslated(field.fieldTypeDisplayNameFr, field.fieldTypeDisplayNameEn))
        }))
    }

    public clearAllLoadedData():void {
        this.clearCvlData();
        this.clearCvlFields();
    }

    public clearCvlData():void {
        this.cvlData.next([])
        localStorage.removeItem(LocalStorage.CVL_DATA)
    }

    public clearCvlFields():void {
        this.cvlFields.next([])
        localStorage.removeItem(LocalStorage.CVL_FIELDS_DATA)
    }

    private putFirstCharacterInUppercase(_string:string) {
        return _string.charAt(0).toUpperCase() + _string.slice(1)
    }
}

interface CvlExtended {
    cvlId: string;
    cvlData: Cvl[]
}